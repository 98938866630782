<template>
  <div class="outer-view-wrapper" v-if="site">
    <sv-section
      sectionName="leistungen-section"
      class="small-card-wrapper"
      :sectionEnabled="site['leistungen-section'].display"
      id="leistungen"
    >
      <h1 class="section-headline">
        <editable
          :document="$options.name"
          :dataEditId="`leistungen-section.headline`"
        ></editable>
      </h1>
      <p class="headline-caption">
        <editable
          :document="$options.name"
          :dataEditId="`leistungen-section.headline-caption`"
        ></editable>
      </p>
      <div>
        <small-card
          :site="site"
          :index="n"
          :document="$options.name"
          section="leistungen-section"
          :smallCardEnabled="
            site['leistungen-section']['small-cards'][n]['display']
          "
          v-for="n in Object.keys(site['leistungen-section']['small-cards'])
            .length"
          v-bind:key="n"
        ></small-card>
      </div>
      <p style="margin-top: 30px">
        <editable
          :document="$options.name"
          :dataEditId="`leistungen-section.extra-field1`"
        ></editable>
      </p>

      <p>
        <editable
          :document="$options.name"
          :dataEditId="`leistungen-section.extra-field2`"
        ></editable>
      </p>
    </sv-section>

    <sv-section
      sectionName="vorstellen-section"
      id="vorstellen"
      class="big-card-wrapper"
      :sectionEnabled="site['vorstellen-section'].display"
    >
      <h1 class="section-headline">
        <editable
          :document="$options.name"
          :dataEditId="`vorstellen-section.headline`"
        ></editable>
      </h1>
      <p class="headline-caption">
        <editable
          :document="$options.name"
          :dataEditId="`vorstellen-section.headline-caption`"
        ></editable>
      </p>
      <div>
        <big-card
          section="vorstellen-section"
          :site="site"
          :index="n"
          :document="$options.name"
          v-for="n in Object.keys(site['vorstellen-section']['big-cards'])
            .length"
          v-bind:key="n"
          :bigCardsEnabled="
            site['vorstellen-section']['big-cards'][n]['display']
          "
        ></big-card>
      </div>
    </sv-section>

    <sv-section
      sectionName="news-section"
      class="news-section-wrapper"
      id="news"
      :sectionEnabled="site['news-section'].display"
    >
      <h1 class="section-headline">
        <editable
          :document="$options.name"
          :dataEditId="`news-section.headline`"
        ></editable>
      </h1>
      <p class="headline-caption">
        <editable
          :document="$options.name"
          :dataEditId="`news-section.headline-caption`"
        ></editable>
      </p>
      <div>
        <news
          section="news-section"
          :site="site"
          :index="n"
          :document="$options.name"
          v-for="n in Object.keys(site['news-section']['news']).length"
          v-bind:key="n"
          :newsEnabled="site['news-section']['news'][n]['display']"
        ></news>
      </div>
    </sv-section>

    <sv-section
      sectionName="laufzeit-section"
      id="laufzeit"
      class="laufzeit-wrapper"
      :sectionEnabled="site['laufzeit-section'].display"
    >
      <h1 class="section-headline">
        <editable
          :document="$options.name"
          :dataEditId="`laufzeit-section.headline`"
        ></editable>
      </h1>
      <p class="headline-caption">
        <editable
          :document="$options.name"
          :dataEditId="`laufzeit-section.headline-caption`"
        ></editable>
      </p>
      <div>
        <from-to-chart
          :from="site['laufzeit-section']['from-to-charts'][n].from"
          :max="site['laufzeit-section']['from-to-charts'][n].max"
          :to="site['laufzeit-section']['from-to-charts'][n].to"
          v-for="n in Object.keys(site['laufzeit-section']['from-to-charts'])
            .length"
          v-bind:key="n"
          :document="$options.name"
          :site="site"
          :n="n"
          :chartEnabled="
            site['laufzeit-section']['from-to-charts'][n]['display']
          "
        >
          <editable
            :document="$options.name"
            :dataEditId="`laufzeit-section.from-to-charts.${n}.text`"
          ></editable>
        </from-to-chart>
      </div>
    </sv-section>

    <sv-section
      sectionName="kontakt-section"
      id="kontakt"
      class="sv-email-form-wrapper"
      :sectionEnabled="site['kontakt-section'].display"
    >
      <h1 class="section-headline">
        <editable
          :document="$options.name"
          :dataEditId="`kontakt-section.headline`"
        ></editable>
      </h1>
      <p class="headline-caption">
        <editable
          :document="$options.name"
          :dataEditId="`kontakt-section.post-headline`"
        ></editable>
      </p>
      <form
        ref="form"
        class="sv-email-form"
        :disabled="this.$store.state.cookiesAccepted == false"
      >
        <input
          v-model="contactForm.name"
          type="text"
          name="name"
          :placeholder="site['kontakt-section'].form['placeholder-name']"
        />
        <div class="sv-email-form-row">
          <input
            v-model="contactForm.email"
            type="email"
            name="email"
            :placeholder="site['kontakt-section'].form['placeholder-email']"
          />
          <input
            v-model="contactForm.number"
            type="text"
            name="number"
            :placeholder="site['kontakt-section'].form['placeholder-number']"
            style="margin-top: 0"
          />
        </div>
        <textarea
          v-model="contactForm.text"
          name="text"
          :placeholder="site['kontakt-section'].form['placeholder-message']"
        ></textarea>
        <button
          :disabled="disableSubmit"
          @click="validateAndSubmit"
          class="email-form-submit"
          type="button"
        >
          {{ disableSubmit ? "..." : "" }}
          <editable
            v-if="!disableSubmit"
            :document="$options.name"
            :dataEditId="`kontakt-section.form.button`"
          ></editable>
        </button>
        <p v-if="!this.$store.state.cookiesAccepted">
          <editable
            v-if="!disableSubmit"
            :document="$options.name"
            :dataEditId="`kontakt-section.form.no-cookies`"
          ></editable>
        </p>
      </form>
    </sv-section>
    <div id="email-status-text">{{ emailStatusBarText }}</div>
  </div>
</template>

<script>
import SvSection from "@/components/SvSection.vue";
import SmallCard from "@/components/SmallCard.vue";
import BigCard from "@/components/BigCard.vue";
import FromToChart from "@/components/FromToChart.vue";
import News from "@/components/News.vue";

export default {
  props: ["computedDbData"],
  name: "Home",
  data: () => ({
    site: null,
    contactForm: {
      text: "",
      number: "",
      name: "",
      email: "",
    },
    disableSubmit: false,
    emailStatusBarText: "",
  }),
  components: {
    "sv-section": SvSection,
    "small-card": SmallCard,
    "big-card": BigCard,
    "from-to-chart": FromToChart,
    news: News,
  },
  watch: {
    computedDbData: {
      immediate: true,
      handler(newVal) {
        try {
          this.site = newVal[this.$options.name];
        } catch {}
      },
    },
    emailStatusBarText: {
      handler() {
        setTimeout(() => {
          this.emailStatusBarText = "";
        }, 2000);
      },
    },
  },
  methods: {
    validateAndSubmit() {
      if (
        !this.disableSubmit &&
        this.validateEmailForm() &&
        this.$store.state.cookiesAccepted
      ) {
        this.disableSubmit = true;
        this.$recaptcha("sendmail").then((token) => {
          this.axios
            .get(
              `https://europe-west3-sv-behr.cloudfunctions.net/verifyRecaptcha?token=${token}&form=${JSON.stringify(
                this.contactForm
              )}`,
              {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              }
            )
            .then((response) => {
              this.disableSubmit = false;
              if (response.status == 200) {
                if (response.data.status === "OK") {
                  this.emailStatusBarText = this.site["kontakt-section"].form[
                    "no-error"
                  ];
                } else if (response.data.status === "BOT") {
                  this.emailStatusBarText = this.site["kontakt-section"].form[
                    "error-robot"
                  ];
                } else {
                  this.site["kontakt-section"].form["error-server"];
                }
              } else {
                this.site["kontakt-section"].form["error-server"];
              }
            })
            .catch((error) => {
              this.disableSubmit = false;
              this.site["kontakt-section"].form["error-server"];
              console.error(error);
            });
        });
      }
    },
    validateEmailForm() {
      if (!this.contactForm.name.length) {
        this.emailStatusBarText = this.site["kontakt-section"].form[
          "error-name"
        ];
      } else if (!this.contactForm.text.length) {
        this.emailStatusBarText = this.site["kontakt-section"].form[
          "error-text"
        ];
      } else if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.contactForm.email
        )
      ) {
        this.emailStatusBarText = this.site["kontakt-section"].form[
          "error-email"
        ];
      } else {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
@import "@/css/variables.scss";
@import "@/css/email-form-section.scss";
@import "@/css/small-card-section.scss";
@import "@/css/big-card-section.scss";
@import "@/css/laufzeit-section.scss";
@import "@/css/leistungen-section.scss";
@import "@/css/news-section.scss";

.outer-view-wrapper > .wrapper:nth-child(2n) {
  background: $dark-section-bg-color;
}

.headline-caption {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 50px;
  line-height: 1.6;
}

.section-headline {
  margin: 0 auto 23.45px auto;
}

#email-status-text {
  $height: 60px;
  position: fixed;
  bottom: 0;
  background: $theme-color;
  width: 100%;
  height: $height;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  box-shadow: 0px 0px 13px 0px #333333;
  letter-spacing: 3px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  transition: all 0.2s ease;

  &:empty {
    bottom: -$height;
  }
}
</style>
