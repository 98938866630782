<template>
  <div :style="{ filter: !smallCardEnabled ? 'opacity(50%)' : '' }"
    v-if="smallCardEnabled || (!smallCardEnabled && this.$store.state.loggedIn)">
    <div @click="openModal" :style="{ transform: this.$store.state.loggedIn ? 'scale(1.0)' : false }"
      class="small-card-alternative">
      <div class="small-card-img">
        <editable :document="document" :dataEditId="`${section}.small-cards.${index}.img`"></editable>
      </div>
      <div class="small-card-content">
        <div class="small-card-headline">
          <editable :document="document" :dataEditId="`${section}.small-cards.${index}.headline`"></editable>
        </div>
        <div class="small-card-extend">
          <editable :document="document" :dataEditId="`${section}.small-cards.${index}.discover-text`"></editable>
        </div>
      </div>
    </div>
    <p class="open-modal" style="cursor: pointer" v-if="this.$store.state.loggedIn" @click="modalVisible = true">
      Edit Text
    </p>
    <button v-if="this.$store.state.loggedIn" @click="updateDisplayStatus">
      {{ smallCardEnabled? "✔️": "❌" }}
    </button>
    <modal :modalVisible="modalVisible" v-if="modalVisible" @close-modal="modalVisible = false">
      <div class="modal-title-wrapper">
        <div class="moda-title">
          <img src="/img/logo-normal.svg" />
          <span>
            <editable :document="document" :dataEditId="`${section}.small-cards.${index}.modal.title`"></editable>
          </span>
        </div>
        <div @click="modalVisible = false" class="modal-x-close"></div>
      </div>
      <div class="modal-text-wrapper">
        <div class="modal-headline">
          <editable :document="document" :dataEditId="`${section}.small-cards.${index}.modal.headline`"></editable>
        </div>
        <div class="modal-text">
          <editable :document="document" :dataEditId="`${section}.small-cards.${index}.modal.text`"></editable>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { db } from "@/db";

export default {
  props: ["index", "document", "site", "section", "smallCardEnabled"],
  name: "SmallCard",
  data: () => ({
    modalVisible: false,
  }),
  components: {
    modal: Modal,
  },
  methods: {
    openModal() {
      if (!this.$store.state.loggedIn) {
        if (
          !(
            this.site[this.section]["small-cards"][this.index]["modal"][
            "text"
            ] === "<p></p>"
          )
        ) {
          this.modalVisible = true;
        }
      }
    },
    updateDisplayStatus() {
      if (this.$store.state.loggedIn) {
        let toUpdate = {};
        toUpdate[`leistungen-section.small-cards.${this.index}.display`] = !this
          .smallCardEnabled;

        let dbName = "sites-en";
        if (this.$cookie.get("language") === "de") {
          dbName = "sites";
        }

        db.collection(dbName)
          .doc(this.document)
          .update(toUpdate)
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/css/variables.scss";

.open-modal {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  border: 3px solid #132746;
  padding: 14px 12px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  display: inline-block;
  font-size: 13px;
  background: white;
  color: $theme-color;
  margin-top: 5px;
  display: inline-block;

  &:hover {
    background: #132746;
    color: white;
  }
}

.small-card-alternative {
  @extend .flex-center-center;
  $border-radius: 10px;
  align-items: flex-start;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: $border-radius;
  transition: all 0.1s ease;
  cursor: pointer;
  align-items: center;
  position: relative;
  height: 151px;

  &:hover {
    box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
  }

  .small-card-headline {
    color: black;
    font-size: 17px;
  }

  .small-card-content {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    max-width: 410px;
    justify-content: center;
    position: relative;
    align-self: stretch;
  }

  .small-card-extend {
    color: $theme-color;
    margin-bottom: 15px;
    line-height: 1;
    margin-top: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px 20px;
    padding-bottom: 0;
    width: 100%;
  }

  .small-card-img {
    position: relative;

    img {
      height: 151px;
      width: 180px;
      object-fit: cover;
      flex-shrink: 0;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }
}

@media only screen and (max-width: 600px) {
  .small-card-alternative {
    margin: 0 auto;
    hyphens: auto;

    .small-card-img {
      //display: none;
    }
  }
}
</style>
