<template>
  <div
    :style="{ filter: !bigCardsEnabled ? 'opacity(50%)' : '' }"
    v-if="bigCardsEnabled || (!bigCardsEnabled && this.$store.state.loggedIn)"
    class="card-wrapper"
  >
    <div class="card-image">
      <editable
        :document="document"
        :dataEditId="`${section}.big-cards.${index}.img`"
      ></editable>
    </div>

    <div class="card-body">
      <div class="card-image-caption">
        <editable
          :document="document"
          :dataEditId="`${section}.big-cards.${index}.caption`"
        ></editable>
      </div>
      <div class="card-headline">
        <editable
          :document="document"
          :dataEditId="`${section}.big-cards.${index}.headline`"
        ></editable>
      </div>
      <div class="card-text">
        <editable
          :document="document"
          :dataEditId="`${section}.big-cards.${index}.text`"
        ></editable>
      </div>
    </div>
    <button v-if="this.$store.state.loggedIn" @click="updateDisplayStatus">
      {{ bigCardsEnabled ? "✔️" : "❌" }}
    </button>
  </div>
</template>

<script>
import { db } from "@/db";

export default {
  props: ["index", "document", "site", "section", "bigCardsEnabled"],
  name: "BigCard",
  methods: {
    updateDisplayStatus() {
      if (this.$store.state.loggedIn) {
        let toUpdate = {};
        toUpdate[`vorstellen-section.big-cards.${this.index}.display`] = !this
          .bigCardsEnabled;

        let dbName = "sites-en";
        if (this.$cookie.get("language") === "de") {
          dbName = "sites";
        }

        db.collection(dbName)
          .doc(this.document)
          .update(toUpdate)
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/css/variables.scss";

.card-wrapper {
  background: white;
  max-width: 545px;
  border-radius: 5px;
  box-shadow: 0px 0px 13px 0px rgba(168, 168, 168, 1);
  margin: 0 auto;
  height: fit-content;
  width: 100%;
}

.card-image {
  img {
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
  }
}

.card-body {
  width: 100%;
  padding: 30px 40px 40px 40px;

  .card-image-caption {
    color: $theme-color;
    font-size: 13px;

    &:not(:empty) {
      margin-bottom: 8px;
    }
  }

  .card-headline {
    font-size: 20px;

    &:not(:empty) {
      margin-bottom: 15px;
    }
  }

  .card-text {
    font-size: 13px;
    color: #807876;
    line-height: 1.8;
  }
}
</style>
