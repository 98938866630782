<template>
  <div
    :style="{ filter: !newsEnabled ? 'opacity(50%)' : '' }"
    v-if="newsEnabled || (!newsEnabled && this.$store.state.loggedIn)"
    class="news-wrapper"
  >
    <div class="news">
      <editable
        :document="document"
        :dataEditId="`${section}.news.${index}.img`"
        class="news-img"
        :style="{ overflow: this.$store.state.loggedIn ? 'auto' : false }"
      ></editable>
      <div class="news-title">
        <editable
          :document="document"
          :dataEditId="`${section}.news.${index}.title`"
        ></editable>
      </div>
      <div class="news-text">
        <editable
          :document="document"
          :dataEditId="`${section}.news.${index}.text`"
        ></editable>
      </div>
    </div>
    <button v-if="this.$store.state.loggedIn" @click="updateDisplayStatus">
      {{ newsEnabled ? "✔️" : "❌" }}
    </button>
  </div>
</template>

<script>
import { db } from "@/db";

export default {
  props: ["index", "document", "site", "section", "newsEnabled"],
  name: "News",
  methods: {
    updateDisplayStatus() {
      if (this.$store.state.loggedIn) {
        let toUpdate = {};
        toUpdate[`news-section.news.${this.index}.display`] = !this.newsEnabled;

        let dbName = "sites-en";
        if (this.$cookie.get("language") === "de") {
          dbName = "sites";
        }

        db.collection(dbName)
          .doc(this.document)
          .update(toUpdate)
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/css/variables.scss";

.news {
  width: 100%;
  margin: 0 auto;
}

.news-img {
  max-height: 300px;
  overflow: hidden;
  border-radius: 10px;

  > img {
    width: 100%;
  }
}

.news-title {
  width: 100%;
  margin-top: 20px;
  font-size: 25px;
}

.news-text {
  margin-top: 20px;
  margin-bottom: 50px;
  line-height: 1.8;
  font-size: 13px;
}

.news-wrapper:last-of-type .news-text {
  margin-bottom: 0;
}
</style>
