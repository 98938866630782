<template>
  <div class="modal">
    <div class="modal-content">
      <slot></slot>
      <div class="modal-close-wrapper">
        <button @click="$emit('close-modal')" class="modal-close">Schließen</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data: () => ({})
};
</script>

<style scoped lang="scss">
@import "@/css/variables.scss";

.modal {
  z-index: 110;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  $border-radius: 10px;

  .modal-content {
    width: calc(100% - 30px);
    max-width: 700px;
    max-height: calc(100vh - 40px);
    overflow: auto;
  }

  .modal-title-wrapper {
    background: $footer-background-color;
    padding: 6px 0 6px 20px;
    color: white;
    display: flex;
    align-items: center;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    justify-content: space-between;

    img {
      height: 22px;
      width: auto;
      margin-right: 10px;
    }

    .moda-title {
      display: flex;
    }

    .modal-x-close {
      padding: 15px 15px;
      margin-right: 5px;
      cursor: pointer;

      &::after,
      &::before {
        display: block;
        height: 4px;
        width: 20px;
        background: white;
        content: "";
        border-radius: 100px;
        position: relative;
      }

      &::after {
        bottom: 2px;
        transform: rotate(-45deg);
      }

      &::before {
        top: 2px;
        transform: rotate(45deg);
      }
    }
  }

  .modal-text-wrapper {
    background: white;
    padding: 30px 20px 25px 20px;

    .modal-headline {
      font-size: 25px;
      color: $theme-color;
    }

    .modal-text {
      background: $dark-section-bg-color;
      padding: 10px;
      margin-top: 20px;
      font-size: 15px;
      line-height: 1.8;
      border-radius: $border-radius;
      max-height: 45vh;
      overflow: auto;
    }
  }

  .modal-close-wrapper {
    background: white;
    padding: 20px;
    border-top: 1px solid $dark-section-bg-color;
    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .modal-close {
      cursor: pointer;
      background: $theme-color;
      color: white;
      padding: 10px 12px;
      border: 3px solid $theme-color;
      border-radius: $border-radius;
      transition: all 0.1s ease-in-out;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 13px;
      outline: none;

      &:hover {
        background: transparent;
        color: $theme-color;
      }
    }
  }
}
</style>
