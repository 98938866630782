<template>
  <div
    :style="{ filter: !chartEnabled ? 'opacity(50%)' : '' }"
    v-if="chartEnabled || (!chartEnabled && this.$store.state.loggedIn)"
  >
    <div class="from-to-bar-text">
      <slot></slot>
    </div>
    <div class="from-to-bar">
      <div
        :style="{
          left: `${(fromInput / maxInput) * 100}%`,
          width: `${((toInput - fromInput) / maxInput) * 100}%`,
        }"
        class="colored-bar"
      >
        <span>{{ fromInput }}</span>
        <span>-</span>
        <span>{{ toInput }}</span>
        <span>
          <editable
            document="Home"
            :dataEditId="`laufzeit-section.from-to-charts.${n}.timeunit`"
          ></editable>
        </span>
      </div>
    </div>
    <div v-if="this.$store.state.loggedIn">
      <label for="max">max:</label>
      <input v-model="maxInput" id="max" type="number" />

      <label for="from">from:</label>
      <input v-model="fromInput" id="from" type="number" />

      <label for="to">to:</label>
      <input v-model="toInput" id="to" type="number" />

      <button @click="save" id="safe-from-to-chart">save</button>
      <button @click="updateDisplayStatus">
        {{ chartEnabled ? "✔️" : "❌" }}
      </button>
    </div>
  </div>
</template>

<script>
import { db } from "@/db";

export default {
  props: ["max", "from", "to", "n", "document", "site", "chartEnabled"],
  name: "FromToChart",
  data: () => ({
    fromInput: null,
    maxInput: null,
    toInput: null,
  }),
  mounted() {
    this.fromInput = this.from;
    this.maxInput = this.max;
    this.toInput = this.to;
  },
  methods: {
    save() {
      if (this.$store.state.loggedIn) {
        let toUpdate = {};
        toUpdate[`laufzeit-section.from-to-charts.${this.n}.to`] = parseInt(
          this.toInput
        );
        toUpdate[`laufzeit-section.from-to-charts.${this.n}.from`] = parseInt(
          this.fromInput
        );
        toUpdate[`laufzeit-section.from-to-charts.${this.n}.max`] = parseInt(
          this.maxInput
        );

        let dbName = "sites-en";
        if (this.$cookie.get("language") === "de") {
          dbName = "sites";
        }

        db.collection(dbName)
          .doc(this.document)
          .update(toUpdate)
          .catch((error) => {
            console.error(error);
          });
      }
    },
    updateDisplayStatus() {
      if (this.$store.state.loggedIn) {
        let toUpdate = {};
        toUpdate[`laufzeit-section.from-to-charts.${this.n}.display`] = !this
          .chartEnabled;

        let dbName = "sites-en";
        if (this.$cookie.get("language") === "de") {
          dbName = "sites";
        }

        db.collection(dbName)
          .doc(this.document)
          .update(toUpdate)
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/css/variables.scss";

.from-to-bar-text {
  &:not(:empty) {
    margin-top: 40px;
    line-height: 1.7;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: initial;
  }
}

.from-to-bar {
  width: 100%;
  height: 40px;
  background: $footer-background-color;
  border-radius: 100px;
  margin: 15px 0;

  .colored-bar {
    height: 100%;
    background: $theme-color;
    border-radius: inherit;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 110px;

    > span {
      color: white;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 14px;
      font-weight: initial;
      background: transparent;

      &:last-child {
        margin-left: 4px;
      }
    }
  }
}
</style>
